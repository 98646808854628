
const config = {};

export default config;

if (process.env.NODE_ENV === 'development') {
	config.host = 'ws://127.0.0.1:8080';
//	config.host = 'ws://192.168.1.12:8080';
//	config.host = 'wss://api.voidchat.io';
}
else if (process.env.NODE_ENV === 'production') {
	config.host = 'wss://api.voidchat.io';
}
