import React, { Component } from 'react';
import DevConsole from '../env/DevConsole';

export default class VoidTags extends Component {
	constructor(props) {
		super(props);
		this.onClientTagsChanged = this.onClientTagsChanged.bind(this);

		this.state = {
			tag: ''
		};

		this.client = props.client;
	}

	componentDidMount() {
		const { listener } = this.client;
		listener.on('tagsChanged', this.onClientTagsChanged);
	}

	componentWillUnmount() {
		const { listener } = this.client;
		listener.unOn('tagsChanged', this.onClientTagsChanged);
	}

	onInputChange(e) {
		const change = { [e.target.name]: e.target.value };
		this.setState(change);
	}

	onClientTagsChanged(tags) {
		this.forceUpdate();
	}

	onAddInputTag(e) {
		const { onAddTag } = this.props;
		let { tag } = this.state;
		this.onAddTag(tag);
		tag = '';
		this.setState({ tag });
	}

	onAddTag(tag) {
		const { onAddTag } = this.props;
		DevConsole.log(`[VoidTags] Adding tag "${tag}"`);
		if (onAddTag)
			onAddTag(tag);
	}

	onRemoveTag(e, tag) {
		const { onRemoveTag } = this.props;
		DevConsole.log(`[VoidTags] Removing tag "${tag}"`);
		if (onRemoveTag)
			onRemoveTag(tag);
	}

	onSetTagActive(tag, active) {
		const { onSetTagActive } = this.props;
		if (onSetTagActive)
			onSetTagActive(tag, active);
	}

	renderTag(tag, isUserTag) {
		return (
			<div className="app-tag flex h flex-center">
				{ tag.active === undefined ? null : (
					<div>
						<input type="checkbox" checked={tag.active} onChange={e => this.onSetTagActive(tag.tag, e.target.checked)} />
					</div>
				) }
				<div className="grow app-tag-name">
					<div className="flex-grow-inner">
						{ tag.tag }
					</div>
				</div>
				<div>
					{ isUserTag ? (
						<button className="app-btn fullsize" onClick={ e => this.onRemoveTag(e, tag.tag) }>
							<i className="fas fa-minus-circle"></i>
						</button>
					) : (
						<button className="app-btn fullsize" onClick={ e => this.onAddTag(tag.tag) }>
							<i className="fas fa-thumbtack"></i>
						</button>
					) }
				</div>
			</div>
		);
	}

	render() {
		const { tags } = this.props;
		const { tag } = this.state;

		return (
			<>
				<div className="fullwidth flex h">
					<div className="grow">
						<div className="flex-grow-inner">
							<input className="app-input app-input-sm" type="text" autoComplete="off"
								placeholder="Add tag..."
								name="tag" value={ tag }
								onChange={ e => this.onInputChange(e) } />
						</div>
					</div>
					<div>
						<button className="fullheight app-btn" onMouseDown={ e => this.onAddInputTag(e) } disabled={ tag.length === 0 }>
							<i className="fas fa-plus"></i>
						</button>
					</div>
				</div>
				<div>
					<h3>YOUR TAGS</h3>
					{ Object.keys(tags).map(x => this.renderTag(tags[x], true)) }
					<h3>COMMUNITY TAGS</h3>
					{ Object.keys(this.client.tags).map(x => this.renderTag({ tag: x }, false)) }
				</div>
			</>
		);
	}
}
