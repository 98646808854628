
export default class DevConsole {

	static call(key, args) {
		if (!DevConsole.active) return;
		const func = console[key];
		func.apply(console, args);
	}
	static log() { DevConsole.call('log', arguments); }
	static warn() { DevConsole.call('warn', arguments); }
	static error() { DevConsole.call('error', arguments); }
	static info() { DevConsole.call('info', arguments); }
}

if (process.env.NODE_ENV === 'development')
	DevConsole.active = true;
else if (process.env.NODE_ENV === 'production')
	DevConsole.active = false;
