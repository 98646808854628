import React, { Component } from 'react';

export default class ChoiceOption extends Component {
	render() {
		const { text, tabIndex, callback } = this.props;
		return (
			<div
				className="app-choice-option"
				onClick={(e) => callback(e)}
				tabIndex={tabIndex}
			>
				{ text || 'but why' }
			</div>
		);
	}
}
