import Observable from './Observable';
//import WebSocket from 'ws';

export default class WsMgr {
	constructor(options) {
		this.options = options;
		this.channels = {};

		if (options.pingInterval === undefined)
			options.pingInterval = 30000;

		if (options.reconnect === undefined)
			options.reconnect = false;

		if (options.reconnectPolicy === undefined)
			options.reconnectPolicy = {};

		if (options.reconnectPolicy.timeout === undefined)
			options.reconnectPolicy.timeout = 1000;

		if (options.reconnectPolicy.burstLimit === undefined)
			options.reconnectPolicy.burstLimit = 4;

		if (options.reconnectPolicy.deadLetterTimeout === undefined)
			options.reconnectPolicy.deadLetterTimeout = 60000;
	}

	channel(endpoint) {
		return this.channels[endpoint];
	}

	subscribe(endpoint) {
		var channel = this.channels[endpoint];

		if (channel)
			return channel;

		channel = new WsChannel(this, endpoint);
		this.channels[endpoint] = channel;
		channel.open();

		console.log(`[WsMgr] Subscribed to '${endpoint}'`);

		return channel;
	}

	async subscribeAsync(endpoint) {
		var channel = this.channels[endpoint];

		if (channel)
			return channel;

		channel = new WsChannel(this, endpoint);
		this.channels[endpoint] = channel;
		await channel.open();

		console.log(`[WsMgr] Subscribed to '${endpoint}'`);

		return channel;
	}

	unsubscribe(endpoint) {
		if (endpoint) {
			var channel = this.channels[endpoint];

			if (channel) {
				channel.close();
				this.channels[endpoint] = undefined;
				delete this.channels[endpoint];
				console.log(`[WsMgr] Unsubscribed from '${endpoint}'`);
			}
		}
		else {
			var channels = this.channels;
			for (var endpoint in channels) {
				var channel = channels[endpoint];
				channel.close();
				channels[endpoint] = undefined;
				delete channels[endpoint];
				console.log(`[WsMgr] Unsubscribed from '${endpoint}'`);
			}
		}
	}
}

export class WsChannel extends Observable {
	constructor(mgr, endpoint) {
		super();
		this.onSocketHeartbeat = this.onSocketHeartbeat.bind(this);
		this.onSocketOpen = this.onSocketOpen.bind(this);
		this.onSocketClose = this.onSocketClose.bind(this);
		this.onSocketError = this.onSocketError.bind(this);
		this.onSocketMessage = this.onSocketMessage.bind(this);

		this.mgr = mgr;
		this.endpoint = endpoint;
		this.terminated = false;
		this.reconnectAttempts = 0;
		this.cleanup();
	}

	cleanup() {
		if (this.keepaliveInterval) {
			clearInterval(this.keepaliveInterval);
			this.keepaliveInterval = undefined;
		}
		this.ws = undefined;
		this.awaitingPong = false;
		this.opened = false;
	}

	close() {
		const { ws } = this;
		if (ws) {
			this.terminated = true;
			if (this.opened) {
				if (ws.terminate) ws.terminate();
				else ws.close();
			}
		}
	}

	open() {
		if (this.ws)
			return;

		const url = this.mgr.options.host + this.endpoint;
		this.awaitingPong = false;
		this.terminated = false;
		this.opened = false;

		const ws = this.ws = new WebSocket(url);
		if (!ws.on) ws.on = ws.addEventListener;

		/*const that = this;
		const p = new Promise((resolve, reject) => {
			function resOnce() {
				resolve(ws);
				that.unOn('error', rejOnce);
			}
			function rejOnce(error) {
				reject(error);
				that.unOn('open', resOnce);
			}
			that.onOnce('open', resOnce);
			that.onOnce('error', rejOnce);
			resolve(ws);
		});*/

		ws.on('open', this.onSocketOpen);

		if (ws.ping)
			ws.on('pong', this.onSocketHeartbeat);
		ws.on('error', this.onSocketError);
		ws.on('close', this.onSocketClose);
		ws.on('message', this.onSocketMessage);

		console.log(`[WsChannel] Requested '${this.endpoint}'`);
		return ws;
	}

	send(data) {
		if (!this.opened || this.terminated) throw new Error('Attempted to send data over closed channel');
		if (data === undefined) data = '';
		if (typeof data === 'object') data = JSON.stringify(data);
		if (typeof data !== 'string') data = data.toString();
		this.ws.send(data);
	}

	tryReconnect() {
		const { reconnect, reconnectPolicy } = this.mgr.options;
		const that = this;
		if (reconnect && !this.terminated) {
			this.reconnectAttempts++;
			const burstLimitReached = this.reconnectAttempts >= reconnectPolicy.burstLimit;
			const timeout = burstLimitReached ? reconnectPolicy.deadLetterTimeout : reconnectPolicy.timeout;

			if (burstLimitReached) {
				console.log(`[WsChannel] Burst limit reached. Reconnecting to '${this.endpoint}' after ${timeout} ms...`);
				//this.reconnectAttempts = 0;
			}
			else {
				console.log(`[WsChannel] Reconnecting to '${this.endpoint}' after ${timeout} ms...`);
			}

			setTimeout(() => {
				if (this.terminated) return;
				if (this.ws) return;
				if (this.opened) return;

				// console.log(`[WsChannel] Reconnecting to '${this.endpoint}'`);
				that.open();
			}, timeout);
		}
	}

	onSocketHeartbeat() {
		this.awaitingPong = false;
	}

	onSocketOpen() {
		if (this.terminated) {
			this.close();
			return;
		}

		this.opened = true;
		this.awaitingPong = false;
		this.reconnectAttempts = 0;

		console.log(`[WsChannel] opened '${this.endpoint}'`);

		if (this.ws.ping) {
			const { pingInterval } = this.mgr.options;
			const that = this;
			this.keepaliveInterval = setInterval(function() {
				if (that.awaitingPong) {
					if (that.ws.terminate) that.ws.terminate();
					else that.ws.close();
				}
				else {
					that.awaitingPong = true;
					that.ws.ping(x=>undefined);
				}
			}, pingInterval);
		}

		this.dispatchEvent('open');
	}

	onSocketClose() {

		console.log(`[WsChannel] closed '${this.endpoint}'`);

		this.cleanup();
		this.dispatchEvent('close');
		this.tryReconnect();
	}

	onSocketError(error) {
		this.dispatchEvent('error', error);
		/*if (!this.opened) {
			this.cleanup();
			this.tryReconnect();
		}*/
	}

	onSocketMessage(message) {
		if (this.terminated)
			return;
		try {
			this.dispatchEvent('message', message);
		}
		catch(e) {

		}
	}
}
