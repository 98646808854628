import React, { Component } from 'react';
import DevConsole from '../env/DevConsole';

import { TTL } from '../libs/void';
import { obj2arr, tempColor, colorOp, colorCss, colorFromCss, luminance } from '../libs/utils';

let _nonce = 0;

export default class VoidEvent extends Component {
	constructor(props) {
		super(props);
		this.onEvent = this.onEvent.bind(this);
		this.onTimerStart = this.onTimerStart.bind(this);

		this.client = props.client;
		this.outerRef = React.createRef();
		this._mounted = false;

		this.state = { pulses: [] };
	}

	componentDidMount() {
		const { listener } = this.client;
		listener.on('event', this.onEvent);
		listener.on('messageTimerStart', this.onTimerStart);
		this._mounted = true;
	}

	componentWillUnmount() {
		const { listener } = this.client;
		listener.unOn('event', this.onEvent);
		listener.unOn('messageTimerStart', this.onTimerStart);
		this._mounted = false;
	}

	onTimerStart(e) {
		const { id } = this.props.event;
		const elem = this.outerRef.current;

		if (e.id !== id) return;
		if (!elem) return;

		const orig = elem.style.animationName;
		elem.style.animationName = 'reset';
		void elem.offsetWidth;
		elem.style.animationName = orig;
	}

	onEvent(e) {
		const { id } = this.props.event;
		if (e.target_id !== id) return;

		if (e.type === 'heat') {
			this.pulse(this.props.event.heat * 100);
		}
	}

	pulse(heat) {
		const { pulses } = this.state;

		const color = tempColor(heat);
		const color2 = tempColor(heat + 2000);
		color.a = 0.5;
		color2.a = 0.5;
		const style = {
			boxShadow: `0 0 4px 2px ${colorCss(color)}, 0 0 2px 1px ${colorCss(color2)}`
		};

		const pulse = { id: _nonce, style };
		_nonce = (_nonce+1)%100;

		pulses.push(pulse);
		setTimeout(() => {
			const i = pulses.indexOf(pulse);
			if (i !== -1)
				pulses.splice(i, 1);
			if (this._mounted)
				this.setState({ pulses });
		}, 250);

		this.setState({ pulses });
	}

	getStyles() {
		const { event } = this.props;
		const { mine, heat, style: eventStyle, expires } = event;

		const style = Object.assign({}, eventStyle);

		//const now = (new Date()).getTime();
		//const ttl = expires - now;
		const delay = 250;// - ttl; // CSS pop-in animation
		const duration = TTL + delay;

		style.wrapper = Object.assign({
			animationDelay: `${Math.floor(delay/1000)}s`,
			animationDuration: `${Math.floor(duration/1000)}s`
		}, style.wrapper);

		let diffuse = mine ? { r: 0.1875, g: 0.1875, b: 0.1875 } : { r: 0.125, g: 0.125, b: 0.125 };
		if (style.bg && style.bg.backgroundColor)
			diffuse = colorFromCss(style.bg.backgroundColor);

		let col = diffuse;

		if (heat) {
			col = tempColor(heat * 100);
			col = colorOp(col, diffuse, (a,b) => a * 0.25 + b);
			/*col.a = 0.5;*/
			const backgroundColor = colorCss(col);
			style.bg = Object.assign({}, style.bg, { backgroundColor });
		}

		const lum = luminance(col);
		if (lum > 0.625)
			style.text = Object.assign({}, style.text, { color: '#000000' });

		return style;
	}

	getEntities() {
		const { event } = this.props;
		let { entities: eventEntities } = event;
		if (!eventEntities) eventEntities = {};
		const entities = {
			bgBack: obj2arr(eventEntities.bgBack),
			textBack: obj2arr(eventEntities.textBack),
			textFront: obj2arr(eventEntities.textFront)
		};
		return entities;
	}

	renderEntity(entity) {
		const { content, ...props } = entity;
		return (
			<div { ...props }>{ content === undefined ? null : content}</div>
		);
	}

	render() {
		const { event, onClick } = this.props;
		let { text, mine } = event;
		const { pulses } = this.state;

		const style = this.getStyles();
		const entities = this.getEntities();

		const boxClassName = `app-message-box${mine?' mine':''}`;

		return (
			<div ref={ this.outerRef } className="app-message-wrapper" style={ style.wrapper }>
				<div className={ boxClassName } style={ style.box } onClick={ onClick }>
					<div className="app-message-bg-back" style={ style.bgBack }>
						{ entities.bgBack.map(x => this.renderEntity(x)) }
						{ pulses.map(x => (<div key={x.id} style={ x.style } className="app-message-pulse"></div>)) }
					</div>
					<div className="app-message-bg" style={ style.bg }>
						<div className="app-message-text-back" style={ style.textBack }>
							{ entities.textBack.map(x => this.renderEntity(x)) }
						</div>
						<div className="app-message-text" style={ style.text }>
							{ text }
						</div>
						<div className="app-message-text-front" style={ style.textFront }>
							{ entities.textFront.map(x => this.renderEntity(x)) }
						</div>
					</div>
				</div>
			</div>
		);
	}
}
