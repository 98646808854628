import React, { Component } from 'react';
import ChoiceOption from './ChoiceOption';

export default class ChoiceBar extends Component {

	mapUserChoiceOptions(options) {
		return options.map((option, index) => <ChoiceOption
			key={`user-choice-${option.value}`}
			value={option.value}
			text={option.text}
			callback={option.callback}
			tabIndex={index+3}
		/>);
	}

	render() {
		const { visible, options } = this.props;
		const className = `fullwidth flex h app-choice-bar ${visible ? 'app-choice-bar--visible' : ''}`;
		return (
			<div className={className}>
				{this.mapUserChoiceOptions(options)}
			</div>
		);
	}
}
