
import Observable from './Observable';
import WsMgr from './WsMgr';

const HEX_LUT = '0123456789abcdef';
function time() { return (new Date()).getTime(); }
function bytesToHex(bytes) {
	let str = '';
	for (let i=0; i<bytes.length; i++) {
		const b = bytes[i];
		str += HEX_LUT[b>>4] + HEX_LUT[b&0xf];
	}
	return str;
}
export function rand_id(bytes) {
	return bytesToHex(crypto.getRandomValues(new Uint8Array(bytes || 16)));
}
export function rand_uuid(bytes) {
	return `${rand_id(4)}-${rand_id(2)}-${rand_id(2)}-${rand_id(2)}-${rand_id(6)}`;
}

const HOST = 'ws://127.0.0.1:8080';
export const TTL = 30000;
export const MAX_PAYLOAD_LENGTH = 512;

export class Client {
	constructor(cfg) {
		cfg = cfg || {};
		this.host = cfg.host || HOST;
		this.wsmgr = new WsMgr({
			host: this.host,
			reconnect: true,
			reconnectPolicy: {
				timeout: 1000,
				burstLimit: 4,
				deadLetterTimeout: 30000
			}
		});
		this.events = [];
		this.eventMap = {};
		this.listener = new Observable();
		this.tags = {};
	}

	pushEvent(e) {
		this.events.push(e);
		this.eventMap[e.id] = e;
	}

	removeEvent(e) {
		const { events, eventMap } = this;
		for (let i=0; i<events.length; i++)
			if (events[i] === e)
				events.splice(i--, 1);
		if (eventMap[e.id])
			delete eventMap[e.id];
	}

	startTimer(e) {
		if (e.timer) clearTimeout(e.timer);
		e.timer = setTimeout(x => this.onMessageExpired(e), TTL);
		this.listener.dispatchEvent('messageTimerStart', e);
	}

	onMessageExpired(e) {
		e.timer = undefined;
		this.removeEvent(e);
		this.decollectTags(e);
		this.listener.dispatchEvent('messageExpired', e);
	}

	onReceiveEvent(e) {
		e.recvTime = time();
		switch (e.type) {
			case 'message': {
				this.pushEvent(e);
				this.startTimer(e);
				this.collectTags(e);
				this.listener.dispatchEvent('message', e);
				break;
			}
			case 'heat': {
				const t = this.eventMap[e.target_id];
				if (t) t.heat = (t.heat||0)+1;
				break;
			}
		}
		this.listener.dispatchEvent('event', e);
	}

	receiveVirtualMessage(options) {
		const data = Object.assign({
			virtual: true,
			id: rand_uuid(),
			type: 'message',
			time: time(),
			text: ''
		}, options);
		this.onReceiveEvent(data);
	}

	collectTags(e) {
		const { tags } = this;
		if (!e.tags) return;
		let newTags = 0;
		e.tags.forEach(t => {
			if (!tags[t]) newTags++;
			tags[t] = (tags[t] || 0) + 1;
		});
		if (newTags) this.listener.dispatchEvent('tagsChanged');
	}

	decollectTags(e) {
		const { tags } = this;
		if (!e.tags) return;
		let changed = false;
		e.tags.forEach(t => {
			if (tags[t]) tags[t]--;
			if (tags[t] === 0) {
				delete tags[t];
				changed = true;
			}
		});
		if (changed) this.listener.dispatchEvent('tagsChanged');
	}

	async connect() {
		const that = this;
		const channel = this.wsmgr.subscribe('/connect');
		channel.on('message', e => that.onReceiveEvent(JSON.parse(e.data)));
		/*return new Promise((res, rej) => {
			const ws = new WebSocket(`ws://${that.host}/connect`);

			ws.addEventListener('open', e => {
				console.log('[Void] Connected', e);
				that.ws = ws;
				that.listener.dispatchEvent('connect');
				res();
			});
			ws.addEventListener('close', e => {
				console.log('[Void] Disconnected', e);
				that.ws = undefined;
				that.listener.dispatchEvent('disconnect');
			});
			ws.addEventListener('error', e => {
				console.error(e);
				that.ws = undefined;
				rej(e);
			});
			ws.addEventListener('message', e => that.onReceiveEvent(JSON.parse(e.data)));
		});*/
	}

	channel() {
		const channel = this.wsmgr.channel('/connect');
		if (!channel) throw new Error('Not connected');
		return channel;
	}

	send(text, tags) {
		const data = { type: 'message', text, tags };
		const payload = JSON.stringify(data);

		if (payload.length > MAX_PAYLOAD_LENGTH)
			throw new Error('Payload length exceeds maximum');
		if (text.length === 0)
			throw new Error('Message is empty');

		const channel = this.channel();
		channel.send(payload);
	}

	extend(id) {
		if (typeof id === 'object') id = id.id;
		const e = this.eventMap[id];
		if (!e) return;
		if (e.virtual) return;
		this.startTimer(e);

		const data = { type: 'extend', target_id: e.id };
		const channel = this.channel();
		channel.send(data);
	}
}
