import React, { Component } from 'react';
import InputBar from './InputBar';
import ChoiceBar from './ChoiceBar';

export default class ActionsTray extends Component {

	constructor(props) {
		super(props);
		this.state = {
			choiceBarVisible: false,
			message: '',
		};
	}

	handleChoiceBarVisibility() {
		const { choiceBarVisible } = this.state;
		this.setState({ choiceBarVisible: !choiceBarVisible });
	}

	render() {
		const {
			choiceBarVisible,
		} = this.state;
		const {
			client,
			message,
			tags,
			onInputChange,
			onInputKeyDown,
			onSend,
			handleDrawerVisibility,
		} = this.props;

		const speechClassName = `speech-container${this.state.choiceBarVisible ? ' speech-container--visible' : ''}`;

		return (
			<>
				<div className={speechClassName}><p className={'speech'}>{'How can I help?'}</p></div>
				<div className={'app-action-tray-container'}>
					<InputBar
						choiceBarVisible={() => this.handleChoiceBarVisibility()}
						onInputChange={onInputChange}
						onInputKeyDown={onInputKeyDown}
						onSend={onSend}
						message={message}
						tags={tags}
					/>
				</div>
				<div className={'fullwidth flex h app-choice-bar-container'}>
					<ChoiceBar
						options={[
							{
								value: 10,
								text: 'I want to add/subscribe to a tag.',
								callback: () => handleDrawerVisibility(),
							},
							{
								value: 20,
								text: 'Nevermind.',
								callback: () => this.handleChoiceBarVisibility(),
							},
							{
								value: 30,
								text: 'owo what\'s this?',
								callback: () => {
									onSend(undefined, '/about', undefined);
								},
							},
						]}
						visible={choiceBarVisible}
					/>
				</div>
			</>
		);
	}
}
