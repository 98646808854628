import React, { Component } from 'react';

export default class InputBar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			actionTrayExpanded: false,
			};
	}
	render() {
		const {
			message,
			tags,
			onInputChange,
			onInputKeyDown,
			onSend,
			choiceBarVisible,
		} = this.props;

		const tagList = Object.keys(tags);

		return (
			<>
				{ tagList.length === 0 ? null : (
					<div className="app-tagstrip">
						{ tagList.map(tag => <div>{tag}</div>) }
					</div>
				) }
		<div className="flex h">
	        <div className="app-btn-adornment">
	          <button
	            className="fullheight app-btn app-voidboi"
	            onMouseDown={ e => {
	                e.preventDefault();
	                choiceBarVisible();
	              }
	            }
	            tabIndex={1}
	          >
	            <i className="fas fa-user-astronaut"></i>
	          </button>
	        </div>
	        <div className="grow">
	          <div className="flex-grow-inner">
	            <input className="app-input app-message-input" type="text" autoComplete="off"
	              placeholder="Shout into the void"
	              name="message" value={ message }
	              onChange={ e => onInputChange(e) }
	              onKeyDown={ e => onInputKeyDown(e) }
	              tabIndex={2}
	            />
	          </div>
	        </div>
	        <div>
	          <button
	            className="app-btn app-send"
	            onMouseDown={ e => onSend(e) }
	            disabled={ message.length === 0 }
	            tabIndex={3}
	          >
	            <i className="fas fa-paper-plane"></i>
	          </button>
	        </div>
		  </div>
		  </>
		);
	}
}
