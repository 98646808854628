
export function obj2arr(obj) {
	if (!obj) return [];
	return Object.keys(obj).map(x => obj[x]);
}

export function clamp(x, a, b) { return x<a?a:(x>b?b:x); }
export function clamp01(x) { return x<0?0:(x>1?1:x); }

const T_MIN = 3000.0;		// 1000.0
const T_WHITE = 6700.0;		// 6600.0

function tempComponent(t, p, k) {
	return (k*p)/t + (1-k);
}
function sampleR(t) { return clamp01(tempComponent(t, T_WHITE, 0.46)); }
function sampleG(t) { return clamp01(1 - Math.abs(tempComponent(t, T_WHITE, -0.32) - 1)); }
function sampleB(t) { return clamp01(tempComponent(t, T_WHITE, -0.55)); }

function sampleN(f, x, radius, N) {
	let y = 0;
	const x0 = x-radius;
	const dx = radius*2;
	for (let i=0; i<N; i++)
		y += f(x0+dx*(i/N));
	return y/N;
}

export function tempColor(t) {
	let color = { r: 0.0, g: 0.0, b: 0.0 };
	const radius = 50;
	const n = 3;
	color.r = sampleN(sampleR, t, radius, n);
	color.g = sampleN(sampleG, t, radius, n);
	color.b = sampleN(sampleB, t, radius, n);
	if (t < T_MIN) {
		const x = clamp01(t/T_MIN);
		color = colorOp(color, c => c*x);
	}
	return color;
}

export function colorOp(a, b, f) {
	if (arguments.length === 2)
		return { r: b(a.r), g: b(a.g), b: b(a.b) };
	return { r: f(a.r, b.r), g: f(a.g, b.g), b: f(a.b, b.b) };
}

export function colorCss(color) {
	const { r, g, b, a } = color;
	function c(x) { return clamp(Math.floor(x*256), 0, 255); }
	if (a !== undefined) return `rgba(${c(r)}, ${c(g)}, ${c(b)}, ${clamp(a, 0, 1)})`;
	return `rgb(${c(r)}, ${c(g)}, ${c(b)})`;
}

export function luminance(color) {
	const { r, g, b } = color;
	return 0.299*r + 0.587*g + 0.114*b;
}

function hb(str) { return Number.parseInt(str, 16); }

export function color256FromCss(css) {
	css = css.trim().toLowerCase();
	if (css.indexOf('#') === 0) {
		if (css.length !== 7 && css.length !== 9) return;
		const r = hb(css.substring(1, 3));
		const g = hb(css.substring(3, 5));
		const b = hb(css.substring(5, 7));
		const a = (css.length === 9) ? hb(css.substring(7, 9)) : undefined;
		return { r, g, b, a };
	}
	else if (css.indexOf('rgb') === 0) {
		const m = css.match(/rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/);
		if (!m) return;
		return { r: m[1]*1, g: m[2]*1, b: m[3]*1 };
	}
	else if (css.indexOf('rgba') === 0) {
		const m = css.match(/rgba\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/);
		if (!m) return;
		return { r: m[1]*1, g: m[2]*1, b: m[3]*1, a: m[4]*255 };
	}
}

export function colorFromCss(css) {
	const c256 = color256FromCss(css);
	if (!c256) return;
	const { r, g, b, a } = c256;
	const color = { r: r/255, g: g/255, b: b/255 };
	if (a !== undefined)
		color.a = a/255;
	return color;
}
