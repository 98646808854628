/**
 * @class Observable
 * @module General
 * @description Stores event listeners and calls them when a named event is dispatched.
**/

import EventSource from './EventSource';

export default class Observable {
	constructor() {
		this.mEventSources = {};
		this.mEventListeners = {};
		this.on = this.addEventListener;
		this.unOn = this.removeEventListener;
		this.onOnce = this.addEventListenerOnce;
	}

	registerEvent(event) {
		this.mEventSources[event] = new EventSource();
	}

	registerEvents() {
		for (let i=0; i<arguments.length; i++)
			this.registerEvent(arguments[i]);
	}

	addEventListener(event, func) {
		const eventSource = this.mEventSources[event];
		if (eventSource)
			return eventSource.listen(func);

		let list = this.mEventListeners[event];
		if (list === undefined)
			list = this.mEventListeners[event] = [];

		list.push({
			callback: func,
			once: false
		});
	}

	addEventListenerOnce(event, func) {
		const eventSource = this.mEventSources[event];
		if (eventSource)
			return eventSource.listenOnce(func);

		let list = this.mEventListeners[event];
		if (list === undefined)
			list = this.mEventListeners[event] = [];

		list.push({
			callback: func,
			once: true
		});
	}

	removeAllListeners(event) {
		const map = this.mEventListeners;

		if (event === undefined) {
			for (let key in map) {
				map[key] = undefined;
				delete map[key];
			}
			return;
		}

		const eventSource = this.mEventSources[event];
		if (eventSource)
			eventSource.removeAll();

		const list = map[event];
		if (list !== undefined) {
			map[event] = undefined;
			delete map[event];
		}
	}

	removeEventListener(event, func) {
		const eventSource = this.mEventSources[event];
		if (eventSource)
			eventSource.remove(func);

		const map = this.mEventListeners;
		const list = map[event];
		if (list !== undefined) {
			for (let i=0; i<list.length; i++) {
				if (list[i].callback === func) {
					list.splice(i, 1);
					i--;
				}
			}
			if (list.length === 0) {
				map[event] = undefined;
				delete map[event];
			}
		}
	}

	dispatchEvent(event) {
		const eventSource = this.mEventSources[event];
		if (eventSource)
			eventSource.dispatch.apply(eventSource, Array.from(arguments).slice(1));

		const numArgs = arguments.length - 1;

		const map = this.mEventListeners;
		const list = map[event];
		let result;
		if (list !== undefined) {
			for (let i=0; i<list.length; i++) {
				const item = list[i];
				switch (numArgs) {
					case 0: result = item.callback(); break;
					case 1: result = item.callback(arguments[1]); break;
					case 2: result = item.callback(arguments[1], arguments[2]); break;
					case 3: result = item.callback(arguments[1], arguments[2], arguments[3]); break;
					default: result = item.callback.apply(undefined, Array.from(arguments).slice(1)); break;
				}
				if (item.once && (result === undefined || result === true))
					list.splice(i--, 1);
			}
			if (list.length === 0) {
				map[event] = undefined;
				delete map[event];
			}
		}
	}
}
