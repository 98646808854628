import React, { Component } from 'react';
import SharedFormStateContainer from './components/SharedFormState';

import DevConsole from './env/DevConsole';
import config from './env/config';

import { Client as VoidClient } from './libs/void';

//import './App.css';

export default class App extends Component {
	constructor(props) {
		super(props);
		this.client = new VoidClient({ host: config.host });
	}

	componentDidMount() {
		this.client.connect();
		window.vc = this.client;
	}

	render() {
		return (
			<SharedFormStateContainer
				client={this.client}
			/>
		);
	}
}
