import React, { Component } from 'react';
import DevConsole from '../env/DevConsole';
import VoidEvent from './VoidEvent';

export default class VoidContent extends Component {
	constructor(props) {
		super(props);
		this.onReceiveEvent = this.onReceiveEvent.bind(this);
		this.onMessageExpired = this.onMessageExpired.bind(this);

		this.client = props.client;
	}

	componentDidMount() {
		const { listener } = this.client;
		listener.on('event', this.onReceiveEvent);
		listener.on('messageExpired', this.onMessageExpired);
	}

	componentWillUnmount() {
		const { listener } = this.client;
		listener.unOn('event', this.onReceiveEvent);
		listener.unOn('messageExpired', this.onMessageExpired);
	}

	//shouldComponentUpdate(nextProps, nextState) { return false; }

	onReceiveEvent(e) {
		DevConsole.log(e);
		if (e.type === 'message')
			this.forceUpdate();
	}

	onMessageExpired(e) {
		this.forceUpdate();
	}

	eventFilter(e) {
		const { tags } = this.props;
		const operands = e.tags;
		if (!operands || operands.length === 0) return false;

		// Logical OR (If any tags on the message are one we're looking for)
		// TODO: Talk about whether we want OR or AND (All tags we're looking for must be found on the message)
		for (let i=0; i<operands.length; i++)
			if (tags[operands[i]]) return true;

		return false;
	}

	render() {
		const { onMessageClick, tags } = this.props;
		const { events } = this.client;

		let finalEvents = events || [];
		if (Object.keys(tags).length > 0)
			finalEvents = finalEvents.filter(e => this.eventFilter(e))

		const blocks = finalEvents.map(e => (
			<VoidEvent key={ e.id }
					client={ this.client }
					event={ e }
					onClick={ x => onMessageClick && onMessageClick(e) } />
		));

		blocks.reverse();

		return (
			<div className="app-content fullheight scroll-y">
				{ blocks }
			</div>
		);
	}
}
