import React, { Component } from 'react';
import Drawer from './Drawer';
import VoidContent from './VoidContent';
import VoidTags from './VoidTags';
import ActionsTray from './ActionsTray';

import DevConsole from '../env/DevConsole';

const ERROR_STYLE = {
	text: { color: '#ff8080' },
	bg: { backgroundColor: '#802020' }
};

export default class SharedFormStateContainer extends Component {

	constructor(props) {
		super(props);
		this.onSend = this.onSend.bind(this);
		this.state = {
			message: '',
			tags: {},
			activeTags: {},
			drawerVisible: false,
		};
	}

	componentDidMount() {
		this.loadState();
	}

	loadState() {
		let tags = localStorage.getItem('void/tags');
		try { tags = JSON.parse(tags || '{}'); }
		catch(e) { tags = {}; }

		const activeTags = {};
		Object.keys(tags).filter(k=>tags[k].active).forEach(k=>activeTags[k]=true);

		this.setState({ tags, activeTags });
	}

	onInputKeyDown(e) {
		if (e.target.name === 'message' && e.keyCode === 13)
			this.onSend();
	}

	onInputChange(e) {
		const change = { [e.target.name]: e.target.value };
		this.setState(change);
	}

	onSend(e, message, tags) {
		const { client } = this.props;
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
		try {
			if (!tags) tags = this.state.tags;
			const tagList = Object.keys(tags).filter(k=>tags[k].active);

			if (message) {
				client.send(message, tagList.length ? tagList : undefined);
			}
			else {
				message = this.state.message;
				client.send(message, tagList.length ? tagList : undefined);
				message = '';
				this.setState({ message });
			}
		}
		catch(e) {
			DevConsole.error(e);
			client.receiveVirtualMessage({ text: 'Your message did not reach the void. Try again later.', style: ERROR_STYLE });
		}
		return false;
	}

	onAddTag(tag) {
		const { tags } = this.state;
		if (!tags[tag])
			tags[tag] = { tag, active: false };
		localStorage.setItem('void/tags', JSON.stringify(tags));
		this.setState({ tags });
	}

	onRemoveTag(tag) {
		const { tags } = this.state;
		if (tags[tag]) delete tags[tag];
		localStorage.setItem('void/tags', JSON.stringify(tags));
		this.setState({ tags }, () => this.onSetTagActive(tag, false));
	}

	onSetTagActive(tagName, active) {
		const { tags } = this.state;

		const tag = tags[tagName];
		if (tag)
			tag.active = active === undefined ? !tag.active : active;

		const activeTags = {};
		Object.keys(tags).filter(k=>tags[k].active).forEach(k=>activeTags[k]=true);

		localStorage.setItem('void/tags', JSON.stringify(tags));
		this.setState({ tags, activeTags });
	}

	handleDrawerVisibility() {
		const { drawerVisible } = this.state;
		this.setState({ drawerVisible: !drawerVisible });
	}

	render() {
		const { client } = this.props;
		const { message, tags, activeTags, drawerVisible } = this.state;
		return (
			<>
				<Drawer
					handleDrawerVisibility={() => this.handleDrawerVisibility()}
					visible={ drawerVisible }
				>
					<VoidTags
						client={ client }
						tags={ tags }
						onAddTag={tag => this.onAddTag(tag)}
						onRemoveTag={tag => this.onRemoveTag(tag)}
						onSetTagActive={(tag, active) => this.onSetTagActive(tag, active)}
					/>
				</Drawer>
				<div className="fullheight flex v">
					<div className="grow">
						<div className="flex-grow-inner">
							<VoidContent
								client={ client }
								tags={ activeTags }
								onMessageClick={ e => client.extend(e) }
							/>
						</div>
					</div>
					<ActionsTray
						client={client}
						onInputChange={(e) => this.onInputChange(e)}
						onInputKeyDown={(e) => this.onInputKeyDown(e)}
						onSend={this.onSend}
						handleDrawerVisibility={() => this.handleDrawerVisibility()}
						message={ message }
						tags={ activeTags }
					/>
				</div>
			</>
		);
	}
}
