
export default class EventSource {
	mListeners = undefined;
	mOnceListeners = undefined;

	constructor() {
		this.mListeners = [];
		this.mOnceListeners = [];
	}

	listen(func) {
		this.mListeners.push(func);
	}
	listenOnce(func) {
		this.mOnceListeners.push(func);
	}
	removeAll() {
		this.mListeners = [];
		this.mOnceListeners = [];
	}
	remove(func) {
		let list = this.mListeners, length = list.length;
		let i;

		for (i=0; i<length; i++)
			if (list[i] === func)
				list.splice(i--, 1);

		list = this.mOnceListeners;
		length = list.length;

		for (i=0; i<length; i++)
			if (list[i] === func)
				list.splice(i--, 1);
	}
	dispatch() {
		const numArgs = arguments.length;
		let list = this.mListeners, length = list.length;
		let i, result;
		switch (numArgs) {
			case 0: for (i=0; i<length; i++) list[i](); break;
			case 1: for (i=0; i<length; i++) list[i](arguments[0]); break;
			case 2: for (i=0; i<length; i++) list[i](arguments[0], arguments[1]); break;
			case 3: for (i=0; i<length; i++) list[i](arguments[0], arguments[1], arguments[2]); break;
			default: for (i=0; i<length; i++) list[i].apply(undefined, arguments); break;
		}

		list = this.mOnceListeners;
		length = list.length;

		if (length === 0)
			return;

		this.mOnceListeners = [];

		switch (numArgs) {
			case 0: for (i=0; i<length; i++) list[i](); break;
			case 1: for (i=0; i<length; i++) list[i](arguments[0]); break;
			case 2: for (i=0; i<length; i++) list[i](arguments[0], arguments[1]); break;
			case 3: for (i=0; i<length; i++) list[i](arguments[0], arguments[1], arguments[2]); break;
			default: for (i=0; i<length; i++) list[i].apply(undefined, arguments); break;
		}
	}
}
