import React, { Component } from 'react';

export default class Drawer extends Component {
	constructor(props) {
		super(props);
		this.onBodyPointerDown = this.onBodyPointerDown.bind(this);
	}

	componentDidMount() {
		document.body.addEventListener('pointerdown', this.onBodyPointerDown);
	}

	componentWillUnmount() {
		document.body.removeEventListener('pointerdown', this.onBodyPointerDown);
	}

	onBodyPointerDown(e) {
		const { visible, handleDrawerVisibility } = this.props;
		const w = window.innerWidth;
		const x = e.clientX;

		(!visible && x < 20) && handleDrawerVisibility();
	}

	render() {
		const { visible, children, handleDrawerVisibility } = this.props;
		const className = `drawer${visible?' visible':''}`;

		return (
			<div className={ className }>
				<div className="drawer-backdrop" onClick={() => handleDrawerVisibility()}></div>
				<div className="drawer-bg">
					{ children || null }
				</div>
			</div>
		);
	}
}
